import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import {BACKEND_URL} from '@/za_conf';
import {CustomerPartEntry, FileEntry} from '@/customer/part_details';
import {DefaultValuesCustomer} from '@/customer/defaults_customer';

Vue.use(Vuex);

export interface OfferSet {
    positions: OfferPosition[];
}

type OfferSelectionMap = Record<string, SelectionDetail>;

export interface SelectionDetail {
    selectedIndex: number;
    comment: string;
}

export interface OfferPosition {
    id: number;
    name?: string;
    estimated_price?: number;
    blackening_price: number;
    blackening_area: number;
    offeredPrice?: number;
    kpi1?: number;
    kpi1_filter?: number;
    kpi2?: number;
    kpi2_filter?: number;
    names: string[];
    counts: number[];
    filter_messages: string[];
    blackenings: boolean[];
}

export interface UUIDToCustomerPartEntry {
    [key: string]: CustomerPartEntry;
}

export interface User {
    id: number;
    role: number;
    name: string;
}

export interface EMail {
    emailSender: '';
    emailRecipients: '';
    emailBody: '';
    emailSubject: '';
    emailAttachments: [];
    emailId: null;
    excels: [];
}

export interface CurrentOffer {
    id?: number;
    name?: string;
    sets: OfferSet[];
}

export interface EmailContact {
    customer_number: string | null;
    company_name: string | null;
    person_forename: string | null;
    person_surname: string | null;
    address: string | null;
    plz: string | null;
    city: string | null;
    phone: string | null;
    telefax: string | null;
}

interface State {
    user?: User;
    parts: UUIDToCustomerPartEntry;
    partFiles: Record<string, FileEntry>;
    // atm currentOffer is only partname -> details
    currentOffer: {};
    currentOfferSelection: OfferSelectionMap;
    currentOfferName?: string;
    currentOfferId?: number;
    currentOfferDetail?: any;
    currentOfferType?: any;
    currentOfferStatus?: any;

    excelPayload?: any;
    email?: EMail;
    emailContact?: EmailContact;
    excelUUID?: any;
    excelVersion?: any;

    [key: string]: State[keyof State];

    isNavDrawerOpen: boolean;
    currentStepCalcDialog: number;

    charge_size: number;

    processedMaterials: [];
    processedMaterialsStructural: [];
    thicknessPerMat: {};

    defaultValues?: DefaultValues;
}

function initialOfferState(): CurrentOffer {
    return {
        id: undefined,
        name: undefined,
        sets: []
    };
}

function initialState(): State {
    return {
        currentOffer: initialOfferState(),
        currentOfferName: undefined,
        currentOfferId: undefined,
        currentOfferSelection: {},
        currentOfferType: undefined,
        currentOfferStatus: undefined,
        user: undefined,
        partFiles: {},
        parts: {},
        isNavDrawerOpen: false,
        currentStepCalcDialog: 0,
        charge_size: 3,
        processedMaterials: [],
        processedMaterialsStructural: [],
        thicknessPerMat: {},
        defaultValues: undefined
    };
}

export function formatOfferId(offerId: number) {
    return '45' + zeroPad(offerId, 5);
}

function zeroPad(num: any, places: any) {
    return String(num).padStart(places, '0');
}

function getNameForUUID(state: State, uuid: string): string | null {
    const part = Object.values(state.partFiles).find(file => {
        return file.uuid === uuid;
    });
    return part ? part.name : null;
}

// USE MODULES IN FUTURE TO CLEANUP STORE
export default new Vuex.Store({
    state: initialState,
    getters: {
        emailContact: state => {
            return state.emailContact;
        },
        getCurrentOfferStatus: state => {
            return state.currentOfferStatus;
        },
        excelPayload: state => {
            return state.excelPayload;
        },
        excelVersion: state => {
            return state.excelVersion;
        },
        excelUUID: state => {
            return state.excelUUID;
        },
        email: state => {
            return state.email;
        },
        currentOfferDetail: state => {
            return state.currentOfferDetail;
        },
        currentOfferContact: state => {
            if (!state.currentOfferDetail) {
                return null;
            }
            return state.currentOfferDetail.offer_contact;
        },
        defaultValues: state => {
            return state.defaultValues;
        },
        currentOffer: state => {
            return state.currentOffer;
        },
        chargeSize: state => {
            return state.charge_size;
        },
        currentOfferName: state => {
            return state.currentOfferName;
        },
        isAuthenticated: state => {
            return state.user != null;
        },
        currentStepCalcDialog: state => {
            return state.currentStepCalcDialog;
        },
        isCustomer: (state: any) => {
            return state.user && state.user.role > 2;
        },
        hasManuOrAdvancedRights: state => {
            return state.user && state.user.role <= 3;
        },
        hasManufacturerRights: state => {
            return state.user && state.user.role <= 2;
        },
        isAdmin: state => {
            return state.user && state.user.role <= 1;
        },
        parts: (state): UUIDToCustomerPartEntry => {
            return state.parts;
        },
        partFiles: state => {
            return state.partFiles;
        },
        uploadedParts: (state): UUIDToCustomerPartEntry => {
            return state.parts;
        },
        containsFileName: state => (name: string) => {
            return state.partFiles.hasOwnProperty(name);
        },

        getUUIDForFileName: state => (name: string) => {
            return state.partFiles[name].uuid;
        },
        getFileNameForUUID: state => (uuid: string) => {
            return getNameForUUID(state, uuid);
        },
        getFormattedOfferId: (state: any, getters) => {
            return formatOfferId(getters.currentOfferID);
        },
        getOfferByPartname: (state: any) => (name: string) => {
            return state.currentOffer[name];
        },
        isOfferActive: state => {
            return !!state.currentOfferId;
        },
        currentOfferID: state => {
            return state.currentOfferId;
        },
        isNavDrawerOpen: state => {
            return state.isNavDrawerOpen;
        },
        processedMaterials: state => {
            return state.processedMaterials;
        },
        processedMaterialsStructural: state => {
            return state.processedMaterialsStructural;
        },
        thicknessPerMat: state => {
            return state.thicknessPerMat;
        },
        userRole: state => {
            if (state.user) return state.user.role;
            else return -1;
        },
        currentOfferSelection: state => {
            return state.currentOfferSelection;
        }
    },
    mutations: {
        setEmailContact(state, emailContact: EmailContact) {
            Vue.set(state, 'emailContact', emailContact);
        },
        setOfferStatus: (state, status) => {
            Vue.set(state, 'currentOfferStatus', status);
        },
        setExcelVersion: (state, excelVersion) => {
            Vue.set(state, 'excelVersion', excelVersion);
        },
        setExcelUUID: (state, excelUUID) => {
            Vue.set(state, 'excelUUID', excelUUID);
        },
        setExcelPayload: (state, val) => {
            Vue.set(state, 'excelPayload', val);
        },
        resetOffer: state => {
            Vue.set(state, 'currentOffer', {});
            Vue.set(state, 'currentOfferSelection', {});
            Vue.set(state, 'currentOfferName', undefined);
            Vue.set(state, 'currentOfferId', undefined);
            Vue.set(state, 'currentOfferDetails', undefined);
            Vue.set(state, 'currentOfferStatus', undefined);
            Vue.set(state, 'parts', {});
            Vue.set(state, 'partFiles', {});
            Vue.set(state, 'processedMaterials', []);
            Vue.set(state, 'processedMaterialsStructural', []);
            Vue.set(state, 'currentOfferType', undefined);
            Vue.set(state, 'thicknessPerMat', {});
            Vue.set(state, 'currentStepCalcDialog', 0);
        },
        setEmail: (state, email) => {
            Vue.set(state, 'email', email);
        },
        setCurrentStepCalcDialog: (state, step_number) => {
            Vue.set(state, 'currentStepCalcDialog', step_number);
        },
        setUser: (state, user) => {
            Vue.set(state, 'user', user);
        },
        logoutUser: state => {
            const s = initialState();
            // @ts-ignore
            window.$cookies.remove('jwt_header_payload');
            // @ts-ignore
            Object.keys(s).forEach(key => {
                Vue.set(state, key, s[key]);
            });
        },
        setDefaultValues: (state, {disclaimer, maxShortLength, maxLongLength, offerValidityDays}) => {
            state.defaultValues = new DefaultValuesCustomer(disclaimer, maxLongLength, maxShortLength, offerValidityDays);
        },
        addFile: (state, file: FileEntry) => {
            Vue.set(state.partFiles, file.name, file);
        },
        addPart: (state, part: CustomerPartEntry) => {
            const partEntry = new CustomerPartEntry();
            Object.keys(part).forEach(key => {
                if (partEntry.hasOwnProperty(key)) {
                    partEntry[key] = part[key];
                }
            });

            partEntry.setFieldsFromDetailsObject(part.part_details);

            Vue.set(state.parts, part.uuid, partEntry);
        },
        deletePart: (state, {uuid, source_name}) => {
            const source_uuid = state.parts[uuid].source_uuid;
            Vue.delete(state.parts, uuid);
            if (source_uuid) {
                const partsToDelete = Object.keys(state.parts).filter(key => state.parts[key].source_uuid === source_uuid);
                if (partsToDelete.length === 0) {
                    Vue.delete(state.partFiles, source_name);
                }
            }
        },
        deleteFile: (state, {file_uuid, file_name}) => {
            // delete all associated parts
            if (file_uuid) {
                const partsToDelete = Object.keys(state.parts).filter(key => state.parts[key].source_uuid === file_uuid);
                partsToDelete.forEach(partUuid => {
                    Vue.delete(state.parts, partUuid);
                });
            }
            Vue.delete(state.partFiles, file_name);
        },
        setUploadError: (state, {name, message}) => {
            Vue.set(state.partFiles[name], 'error_during_upload', true);
            Vue.set(state.partFiles[name], 'error_message', message);
        },
        updateProgress: (state, {name, progress}) => {
            Vue.set(state.partFiles[name], 'progress', progress);
        },
        setFileUUID: (state, {name, uuid}) => {
            Vue.set(state.partFiles[name], 'uuid', uuid);
        },
        setPartUUID: (state, {uuid}) => {
            Vue.set(state.parts[uuid], 'uuid', uuid);
        },
        updatePartProperties: (state, {uuid, properties}) => {
            for (const prop_name of Object.keys(properties)) {
                Vue.set(state.parts[uuid], prop_name, properties[prop_name]);
            }
        },
        updatePartOrderSingleKey: (state, {uuid, key, value}) => {
            Vue.set(state.parts[uuid], key, value);
        },
        updatePartOrder: (state, {uuid, counts, material, thickness_info, bends, threads, countersinks, finish}) => {
            Vue.set(state.parts[uuid], 'counts', counts);
            Vue.set(state.parts[uuid], 'material', material);
            Vue.set(state.parts[uuid], 'thickness_info', thickness_info);
            Vue.set(state.parts[uuid], 'bends', bends);
            Vue.set(state.parts[uuid], 'finish', finish);
            Vue.set(state.parts[uuid], 'threads', threads);
            Vue.set(state.parts[uuid], 'countersinks', countersinks);
        },
        updatePartShipping: (state, {name, shipping}) => {
            Vue.set(state.parts[name], 'shipping', shipping);
        },
        setHasImageFlag: (state, {name}) => {
            Vue.set(state.parts[name], 'has_image', true);
        },
        setOfferID: (state, offerID: number) => {
            Vue.set(state, 'currentOfferId', offerID);
        },
        setOffer: (state, {offer_id, offer_detail, name, offer_type}) => {
            Vue.set(state, 'currentOfferId', offer_id);
            Vue.set(state, 'currentOfferDetail', offer_detail);
            Vue.set(state, 'currentOfferName', name);
            Vue.set(state, 'currentOfferType', offer_type);
        },
        updateOnRequest: (state, {part_name, on_request_details}) => {
            state.parts[part_name].setFieldsFromOnRequestDetailsObject(on_request_details);
        },
        updateDrawerState: (state, flag) => {
            state.isNavDrawerOpen = flag;
        },
        updateCurrentOffer: (state, data) => {
            Vue.set(state, 'currentOffer', {});
            Vue.set(state, 'currentOffer', data);
        },

        updateCurrentOfferSelection: (state, {uuid, selectedIndex, comment}) => {
            Vue.set(state.currentOfferSelection, uuid, {
                selectedIndex: selectedIndex,
                comment: comment
            });
        },
        updateOfferedPriceCurrentOffer: (state, offeredPrice) => {
            Vue.set(state.currentOffer, 'offeredPrice', offeredPrice);
        }
    },
    actions: {
        updateRefreshDefaults: ({commit}) => {
            axios
                .get(`${BACKEND_URL}/defaults`)
                .then(response => {
                    if (response.status === 200) {
                        commit('setDefaultValues', {
                            disclaimer: response.data.disclaimer,
                            maxShortLength: response.data.max_short_length,
                            maxLongLength: response.data.max_long_length,
                            offerValidityDays: response.data.offer_validity_days
                        });
                    }
                })
                .catch();
        },
        updatePartOrder: ({commit, dispatch}, payload) => {
            commit('updatePartOrder', payload);
            dispatch('updatePartRemote', payload.uuid);
        },
        updatePartFinish: ({commit, dispatch}, payload) => {
            commit('updatePartFinish', payload);
            dispatch('updatePartRemote', payload.name);
        },
        updatePartShipping: ({commit, dispatch}, payload) => {
            commit('updatePartShipping', payload);
            dispatch('updatePartRemote', payload.name);
        },
        setHasImageFlag: ({commit, dispatch}, payload) => {
            commit('setHasImageFlag', payload);
            // TODO: too many calls using this... leads to multiple calculations in database
            //dispatch('updatePartRemote', payload.name);
        },
        loadPendingOffer: ({commit, dispatch}) => {
            axios
                .get(`${BACKEND_URL}/pending_offer`)
                .then(response => {
                    if (response.status === 200) {
                        if ('offer_id' in response.data) {
                            commit('setOffer', {
                                offer_id: response.data.offer_id,
                                offer_detail: response.data.offer_detail,
                                name: response.data.offer_name
                            });
                            if (response.data.parts.length > 0) {
                                dispatch('calculateOffer');
                            }
                            for (const part of response.data.parts) {
                                commit('addPart', part);
                            }
                        }
                    }
                })
                .catch(error => {
                });
        },
        updatePartRemote: ({state, dispatch, commit}, part_uuid) => {
            axios
                .post(`${BACKEND_URL}/update_offer_part`, {
                    offer_id: state.currentOfferId,
                    ...state.parts[part_uuid].createUpdateObject()
                })
                .then(response => {
                    dispatch('calculateOffer');
                });
        },
        updateOfferedPrice: ({state, commit}, {pos_id, price}) => {
            axios
                .post(`${BACKEND_URL}/update_offered_price`, {
                    pos_id: pos_id,
                    offered_price: price
                })
                .then(response => {
                    commit('updateOfferedPriceCurrentOffer', price);
                })
                .catch(error => {
                });
        },
        deleteFile: ({commit, getters, dispatch}, file_name) => {
            const file_uuid = getters.getUUIDForFileName(file_name);
            axios
                .post(`${BACKEND_URL}/upload/rm`, {
                    file_uuid: file_uuid,
                    offer_id: getters.currentOfferID
                })
                .then(() => {
                    commit('deleteFile', {file_name, file_uuid});
                    dispatch('calculateOffer');
                })
                .catch(() => {
                });
        },
        deletePart: ({state, commit, getters, dispatch}, part_uuid) => {
            axios
                .post(`${BACKEND_URL}/upload/rm_part`, {
                    part_uuid: part_uuid,
                    offer_id: getters.currentOfferID
                })
                .then(() => {
                    const source_name = getNameForUUID(state, state.parts[part_uuid].source_uuid);
                    commit('deletePart', {uuid: part_uuid, source_name: source_name});
                    dispatch('calculateOffer');
                })
                .catch(() => {
                });
        },
        loadMaterials: ({state}) => {
            axios
                .get(`${BACKEND_URL}/man/materials`)
                .then(ret => {
                    Vue.set(state, 'processedMaterials', ret.data.materials_standard);
                    Vue.set(state, 'processedMaterialsStructural', ret.data.materials_structural);
                })
                .catch(() => {
                });
        },
        calculateOffer: ({state, commit, dispatch}) => {
            axios
                .post(`${BACKEND_URL}/calc`, {
                    offer_id: state.currentOfferId
                })
                .then(response => {
                    commit('updateCurrentOffer', response.data);
                    for (let key in response.data) {
                        commit('updateOnRequest', {
                            part_name: key,
                            on_request_details: response.data[key].on_request
                        });
                    }
                    //dispatch('updateOfferedPrice', response.data.estimated_price);
                });
        },

        deleteCurrentOffer: ({state, commit}) => {
            axios
                .post(`${BACKEND_URL}/delete_offer`, {
                    offer_id: state.currentOfferId
                })
                .then(response => {
                });
            commit('resetOffer');
        },
        deleteOffer: ({state, commit}, offerId) => {
            axios
                .post(`${BACKEND_URL}/delete_offer`, {
                    offer_id: offerId
                })
                .then(response => {
                });
        }
    }
});
