
import { Component, Prop, Vue } from 'vue-property-decorator';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import { CustomerPartEntry } from '@/customer/part_details';

@Component({})
export default class CalcShippingItem extends Vue {
  @Prop({ type: Object, required: true })
  part!: CustomerPartEntry;

  BACKEND_URL = BACKEND_URL;

  shipping = 'Zwischen 10 und 20 Arbeitstage';

  updatePartShipping(shipping: string) {
    this.shipping = shipping ? shipping : this.shipping;
    this.$store.dispatch('updatePartShipping', {
      name: this.part.name,
      shipping: this.shipping
    });
  }

  created() {
    this.updatePartShipping(this.shipping);
  }
}
