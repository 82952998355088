import { render, staticRenderFns } from "./CalcShippingItem.vue?vue&type=template&id=2d399294&scoped=true"
import script from "./CalcShippingItem.vue?vue&type=script&lang=ts"
export * from "./CalcShippingItem.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d399294",
  null
  
)

export default component.exports