
import { Component, Vue, Watch } from 'vue-property-decorator';
import { BACKEND_URL } from '@/za_conf';
import axios from 'axios';
import CalcOfferItemInternal from '@/components/calculator/CalcOfferItemInternal.vue';
import { CustomerPartEntry } from '@/customer/part_details';
import CalcOfferItemExternal from '@/components/calculator/CalcOfferItemExternal.vue';

const Big = require('big.js');
let pdfMake = require('pdfmake/build/pdfmake.js');
let pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  components: {
    calcofferiteminternal: CalcOfferItemInternal,
    calcofferitemexternal: CalcOfferItemExternal
  }
})
export default class CalcOffer extends Vue {
  editedItem: any = {};
  BACKEND_URL = BACKEND_URL;
  offerSets: any = {};
  uuidComments: any = {};
  dialogAfterVerification = false;

  get parts(): CustomerPartEntry[] {
    return Object.values(this.$store.getters.uploadedParts);
  }

  get storeOfferSets() {
    return this.$store.getters.currentOffer;
  }

  get disclaimer() {
    return 'Diese Offerte wurde automatisch erstellt und ist unter Vorbehalt gültig.';
    // return this.$store.getters.defaultValues.disclaimer;
  }

  addedComment(uuid: string, comment: string) {
    this.uuidComments[uuid] = comment;
  }

  offerSelectionChanged(uuid: string, selectedIndex: number, comment: string) {
    this.uuidComments[uuid] = comment;
    this.$store.commit('updateCurrentOfferSelection', {
      uuid,
      selectedIndex,
      comment
    });
  }

  @Watch('storeOfferSets')
  onStoreOfferSetsChange(val: any, old_val: boolean) {
    this.offerSets = Object.assign({}, val);
  }

  showVerificationDialog() {
    this.dialogAfterVerification = true;
  }

  activateVerification(would_accept: boolean) {
    this.dialogAfterVerification = false;
    axios
      .post(`${BACKEND_URL}/comment`, {
        offer_id: this.$store.getters.currentOfferID,
        uuid_comments: this.uuidComments
      })
      .then(response => {
        let selection = this.$store.getters.currentOfferSelection;
        axios
          .post(`${BACKEND_URL}/verify`, {
            offer_id: this.$store.getters.currentOfferID,
            selection: selection,
            accepts: would_accept
          })
          .then(response => {
            this.$router.push('/');
          });
      });
  }

  generatePDF() {
    axios
      .post(`${BACKEND_URL}/comment`, {
        offer_id: this.$store.getters.currentOfferID,
        uuid_comments: this.uuidComments
      })
      .then(response => {
        let selection = this.$store.getters.currentOfferSelection;
        axios
          .post(`${BACKEND_URL}/pdf`, { offer_id: this.$store.getters.currentOfferID }, { responseType: 'blob' })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${this.$store.getters.getFormattedOfferId}.pdf`);
            document.body.appendChild(link);
            link.click();
          })
          .catch(error => {});
      });
  }

  save() {
    axios
      .post(`${BACKEND_URL}/validate`, {
        offer_part_id: this.offerSets[this.editedItem.part_name].offers[this.editedItem.index].offer_part_id,
        data: this.editedItem
      })
      .then(response => {
        this.$store.dispatch('calculateOffer');
      });
  }

  getOnRequestPart(name: string) {
    return this.$store.getters.parts[name].on_request_details.partOnRequest();
  }

  get isCustomer() {
    return this.$store.getters.isCustomer;
  }

  get hasManuOrAdvancedRights() {
    return this.$store.getters.hasManuOrAdvancedRights;
  }
}
