import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export interface GLTFResource {
  identifier: string;
  gltfUrl: string;
}

class GLTFRenderer {
  private renderer: THREE.WebGLRenderer;
  private readonly canvas: HTMLCanvasElement;
  private readonly scenes: { [key: string]: THREE.Scene };
  private gltfLoader: GLTFLoader;

  private animationFrameId: number = 0;

  constructor() {
    this.canvas = document.createElement('canvas');
    this.gltfLoader = new GLTFLoader();
    this.gltfLoader.setWithCredentials(true);
    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, antialias: true });
    this.renderer.setClearColor(0xffffff, 1);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.scenes = {};
  }

  private frameArea(
    sizeToFitOnScreen: number,
    boxSize: number,
    boxCenter: THREE.Vector3,
    camera: THREE.PerspectiveCamera
  ) {
    const halfSizeToFitOnScreen = sizeToFitOnScreen * 0.5;
    const halfFovY = THREE.MathUtils.degToRad(camera.fov * 0.5);
    const distance = halfSizeToFitOnScreen / Math.tan(halfFovY);

    // Set the camera position to be in front of the box and parallel to the z axis
    camera.position.set(boxCenter.x, boxCenter.y, boxCenter.z + distance);

    // pick some near and far values for the frustum that
    // will contain the box.
    camera.near = 0.001;
    camera.far = boxSize * 100;

    camera.updateProjectionMatrix();

    // point the camera to look at the center of the box
    camera.lookAt(boxCenter.x, boxCenter.y, boxCenter.z);
  }

  // call to add new scene
  public createScene(sceneElement: HTMLElement | any, gltfResource: GLTFResource, isModal: boolean = false) {
    const identifier = isModal ? gltfResource.identifier + '-modal' : gltfResource.identifier;

    // if not an HTMLElement, return
    if (!(sceneElement instanceof HTMLElement)) {
      this.removeScene(identifier);
      return;
    }

    cancelAnimationFrame(this.animationFrameId);
    const scene = new THREE.Scene();
    const ctx = document.createElement('canvas').getContext('2d');
    if (ctx !== null) {
      sceneElement.appendChild(ctx.canvas);
    }

    scene.userData.ctx = ctx;
    scene.userData.gltfResource = gltfResource;
    // the element that represents the area we want to render the scene
    scene.userData.element = sceneElement;

    const camera = new THREE.PerspectiveCamera(50, 1, 0.1, 10);
    scene.userData.camera = camera;

    const controls = new OrbitControls(scene.userData.camera, scene.userData.element);
    controls.maxDistance = 5;
    controls.enablePan = true;
    controls.enableZoom = true;
    scene.userData.controls = controls;

    // load geometry
    this.gltfLoader.load(gltfResource.gltfUrl, gltf => {
      const geometry = gltf.scene;

      // rotate geometry
      geometry.rotateX(THREE.MathUtils.degToRad(90));

      let box = new THREE.Box3().setFromObject(geometry);
      let boxSize = box.getSize(new THREE.Vector3()).length();
      const scale = (1.0 / boxSize) * 2;
      geometry.scale.set(scale, scale, scale);
      geometry.updateMatrix();
      box = new THREE.Box3().setFromObject(geometry);
      boxSize = box.getSize(new THREE.Vector3()).length();
      const boxCenter = box.getCenter(new THREE.Vector3());
      this.frameArea(boxSize * 1.1, boxSize, boxCenter, scene.userData.camera);

      const direction = new THREE.Vector3();
      geometry.getWorldDirection(direction);

      controls.maxDistance = boxSize * 10;
      controls.target.copy(boxCenter);
      controls.update();

      scene.userData.initialCameraPosition = scene.userData.camera.position.clone();
      scene.userData.initialCameraTarget = scene.userData.controls.target.clone();

      // const outerPath = new THREE.Shape();
      // outerPath.autoClose = true
      //
      // geometry.traverse((object: THREE.Object3D) => {
      //   console.log(object)
      //   if (object instanceof THREE.Group) {
      //     if (object.name === 'Contour_1') {
      //       const pointso: any[] = []
      //       object.children.forEach((child: THREE.Object3D) => {
      //         if (child instanceof THREE.Line) {
      //           const geometry = child.geometry as THREE.BufferGeometry;
      //           const positionAttribute = geometry.getAttribute('position');
      //           const positions = positionAttribute.array as number[];
      //
      //           for (let i = 0; i < positions.length; i += 3) {
      //             pointso.push([positions[i], -positions[i+2]])
      //           }
      //
      //         }
      //       })
      //       // Create a path from the flat model's vertices
      //       outerPath.moveTo(pointso[0][0], pointso[0][1]);
      //       for (let i = 1; i < pointso.length; i++) {
      //         outerPath.lineTo(pointso[i][0], pointso[i][1]);
      //       }
      //       outerPath.lineTo(pointso[0][0], pointso[0][1]);
      //       //outerPath.lineTo(positions[0], positions[2]);
      //     } else {
      //       const path = new THREE.Shape();
      //       const points: any[] = []
      //       path.autoClose = true
      //       object.children.forEach((child: THREE.Object3D) => {
      //         if (child instanceof THREE.Line) {
      //           const geometry = child.geometry as THREE.BufferGeometry;
      //           const positionAttribute = geometry.getAttribute('position');
      //           const positions = positionAttribute.array as number[];
      //
      //           for (let i = 0; i < positions.length; i += 3) {
      //             points.push([positions[i], -positions[i+2]])
      //           }
      //         }
      //       })
      //       if (points.length > 0) {
      //         points.reverse()
      //         // Create a path from the flat model's vertices
      //         path.moveTo(points[0][0], points[0][1]);
      //         for (let i = 1; i < points.length; i++) {
      //           path.lineTo(points[i][0], points[i][1]);
      //         }
      //         path.lineTo(points[0][0], points[0][1]);
      //         if (path.curves.length !== 0) {
      //           outerPath.holes.push(path);
      //         }
      //       }
      //
      //     }
      //     object.visible = true;
      //   }
      // })
      // const extrudeGeometry = new THREE.ExtrudeGeometry(outerPath, {
      //   depth: 0.025,
      //   bevelEnabled: false,
      //   steps: 2
      // });
      //
      // const material = new THREE.MeshPhongMaterial({color: 0x00ff00, side: THREE.DoubleSide});
      // const sheetMetalMaterial = new THREE.MeshStandardMaterial({
      //   color: 0x999999, // Set the color of the material
      //   roughness: 0.8, // Adjust the roughness to simulate the surface of sheet metal
      //   metalness: 0.9, // Set the metalness to give the material a metallic appearance
      //   side: THREE.DoubleSide, // Render both sides of the material
      // });
      // const extrudedMesh = new THREE.Mesh(extrudeGeometry, sheetMetalMaterial);
      // scene.add(extrudedMesh);

      // change line color of geometry
      geometry.traverse((object: THREE.Object3D) => {
        if (object instanceof THREE.Line) {
          const material = new THREE.LineBasicMaterial({
            color: 0x000000,
            linewidth: 1
          });
          object.material = material;
        }
      });
      scene.add(geometry);
    });

    scene.add(new THREE.HemisphereLight(0xffffff, 0x000000, 0.8));

    const color = 0xffffff;
    const intensity = 1;
    const light = new THREE.DirectionalLight(color, intensity);
    const light2 = new THREE.DirectionalLight(color, intensity);
    const light3 = new THREE.DirectionalLight(color, intensity);
    const light4 = new THREE.DirectionalLight(color, intensity);
    light.position.set(-1, 2, 4);
    light2.position.set(-1, 2, -4);
    light3.position.set(5, 0, 4);
    scene.add(light);
    scene.add(light2);
    scene.add(light3);

    // const popoutIcon = document.createElement("i");
    // popoutIcon.className = isModal ? "mdi mdi-fullscreen-exit" : "mdi mdi-fullscreen"; // Use a suitable icon from your icon set
    // popoutIcon.title = t("renderer.popOut");
    // popoutIcon.style.position = "absolute";
    // popoutIcon.style.right = "10px"; // position it suitably
    // popoutIcon.style.top = "10px";
    // popoutIcon.style.cursor = "pointer";
    // popoutIcon.style.fontSize = "1.5em";
    // popoutIcon.addEventListener('pointerdown', () => {
    //   this.popOutScene(identifier, isModal);
    // })
    //
    // sceneElement.appendChild(popoutIcon);

    // Create an icon for resetting the camera
    const resetIcon = document.createElement('i');
    resetIcon.className = 'mdi mdi-camera-retake-outline'; // Use the Font Awesome "undo" icon
    resetIcon.style.position = 'absolute';
    resetIcon.style.right = '10px';
    resetIcon.style.top = '10px';
    resetIcon.style.cursor = 'pointer';
    resetIcon.style.fontSize = '1.5em';
    resetIcon.addEventListener('pointerdown', () => {
      this.resetCamera(identifier);
    });

    // Add the reset icon to the scene element
    sceneElement.appendChild(resetIcon);

    this.scenes[identifier] = scene;
    this.animationFrameId = requestAnimationFrame(() => this.animate());
  }

  public removeScene(identifier: string) {
    if (identifier in this.scenes) {
      const scene = this.scenes[identifier];

      // Remove all children from the scene's element
      const element = scene.userData.element;
      while (element.firstChild) {
        element.removeChild(element.firstChild);
      }

      // Dispose of materials, geometries and textures
      scene.traverse(object => {
        if (object instanceof THREE.Mesh) {
          if (Array.isArray(object.material)) {
            object.material.forEach(material => material.dispose());
          } else {
            object.material.dispose();
          }
          object.geometry.dispose();
        }
      });

      // Dispose of camera and controls
      if (scene.userData.camera) {
        scene.userData.camera = null;
      }
      if (scene.userData.controls) {
        scene.userData.controls.dispose();
      }

      // Remove the scene from the scenes object
      delete this.scenes[identifier];
    }
  }

  public animate() {
    this.render();
    this.animationFrameId = requestAnimationFrame(() => this.animate());
  }

  public resetCamera(identifier: string) {
    const scene = this.scenes[identifier];
    if (scene) {
      const camera = scene.userData.camera;
      const controls = scene.userData.controls;
      camera.position.copy(scene.userData.initialCameraPosition);
      controls.target.copy(scene.userData.initialCameraTarget);
      controls.update();
    }
  }

  public render() {
    this.renderer.setClearColor(0xffffff);
    this.renderer.setScissorTest(false);
    this.renderer.clear();

    this.renderer.setClearColor(0xf6f6f6);
    this.renderer.setScissorTest(true);
    let count_rendered = 0;
    Object.values(this.scenes).forEach(scene => {
      // get the element that is a place holder for where we want to
      // draw the scene
      const element = scene.userData.element;

      // get its position relative to the page's viewport
      const rect = element.getBoundingClientRect();
      const { left, right, top, bottom, width, height } = rect;

      // check if it's offscreen. If so skip it
      if (
        element.offsetParent === null ||
        bottom < 0 ||
        top > window.innerHeight ||
        right < 0 ||
        left > window.innerWidth
      ) {
        return; // it's off screen
      }
      count_rendered += 1;
      const roundedWidth = Math.round(width);
      const roundedHeight = Math.round(height);

      // make sure the renderer's canvas is big enough
      if (this.renderer.domElement.width < roundedWidth || this.renderer.domElement.height < roundedHeight) {
        this.renderer.setSize(roundedWidth, roundedHeight, false);
      }

      // make sure the canvas for this area is the same size as the area
      if (scene.userData.ctx.canvas.width !== roundedWidth || scene.userData.ctx.canvas.height !== roundedHeight) {
        scene.userData.ctx.canvas.width = roundedWidth;
        scene.userData.ctx.canvas.height = roundedHeight;
      }

      this.renderer.setViewport(0, 0, roundedWidth, roundedHeight);
      this.renderer.setScissor(0, 0, roundedWidth, roundedHeight);

      const camera = scene.userData.camera;

      camera.aspect = roundedWidth / roundedHeight; // not changing in this example
      camera.updateProjectionMatrix();
      scene.userData.controls.update();
      this.renderer.render(scene, camera);
      scene.userData.ctx.globalCompositeOperation = 'copy';
      scene.userData.ctx.drawImage(
        this.renderer.domElement,
        0,
        this.renderer.domElement.height - roundedHeight,
        roundedWidth,
        roundedHeight, // src rect
        0,
        0,
        roundedWidth,
        roundedHeight
      );
    });
  }

  public popOutScene(identifier: string, isModal: boolean) {
    const scene = this.scenes[identifier];
    if (scene) {
      if (isModal) {
        // Remove the modal from the document body
        this.removeScene(identifier);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        document.body.removeChild(scene.userData.element);
      } else {
        // Create the modal element
        const modal = document.createElement('div');
        modal.style.position = 'fixed';
        modal.style.top = '0';
        modal.style.left = '0';
        modal.style.width = '100%';
        modal.style.height = '100%';
        modal.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
        modal.style.zIndex = '9999';

        // Append the renderer's canvas to the modal
        this.createScene(modal, scene.userData.gltfResource, true);
        this.renderer.setPixelRatio(window.devicePixelRatio * 0.5);

        // Append the modal to the document body
        document.body.appendChild(modal);
      }

      // // Add a click event listener to close the modal when clicked outside
      // modal.addEventListener('click', () => {
      //   document.body.removeChild(modal);
      // });
    }
  }
}

export default GLTFRenderer;
