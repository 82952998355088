
import { BACKEND_URL } from '@/za_conf';
import Vue from 'vue';
import ImageViewer from '@/components/util/ImageViewer.vue';
import axios from 'axios';

export default Vue.extend({
  props: {
    editedItems: Array as () => any[],
    offerSet: Object as () => any,
    name: String as () => string,
    uuid: String as () => string,
    comment: String as () => string,
    length: Number as () => number,
    width: Number as () => number,
    onRequest: Boolean as () => boolean
  },
  // type inference enabled
  data() {
    return {
      BACKEND_URL: BACKEND_URL,
      vis_data: { uuid: this.uuid },
      expand: true,
      db_info_exp: false,
      headers: [
        {
          text: 'Position',
          align: 'left'
        },

        { text: 'Einrichtkosten' },
        { text: 'Einrichtkosten Validierung' },
        { text: 'Stückkosten' },
        { text: 'Stückkosten Validierung' },
        { text: 'Total Auftrag' },
        {}
      ]
    };
  },
  computed: {
    material() {
      return this.offerSet.material;
    },
    thickness() {
      return this.offerSet.thickness.thickness;
    },
    finish() {
      return this.offerSet.finish ? this.offerSet.finish.text : '-';
    },
    lasertime() {
      return this.offerSet.estimated_laser_time;
    },
    materialWeight() {
      return this.offerSet.estimated_material_weight;
    }
  },
  methods: {
    getOnRequestStyle(position: string) {
      if (this.offerSet.on_request[`${position}_on_request`]) {
        return 'background-color: orange';
      } else {
        return '';
      }
    },
    getValidatedStyle(item: any, name_1: string, name_2: string) {
      if (item[name_1] != item[name_2]) {
        return 'green lighten-2';
      } else {
        return '';
      }
    },
    validated(value: number, id: number, index_calc: number, index_offer: number, name: string): any {
      let key = Object.keys(this.offerSet.calculation[index_offer].part_calc.part_calc)[index_calc];
      axios
        .post(`${BACKEND_URL}/validate`, {
          offer_part_id: id,
          key: key,
          name: name,
          value: Number(value)
        })
        .then(response => {
          this.$store.dispatch('calculateOffer');
        });
    },
    validatedForKey(value: number, id: number, key: string, name: string): any {
      axios
        .post(`${BACKEND_URL}/validate`, {
          offer_part_id: id,
          key: key,
          name: name,
          value: Number(value)
        })
        .then(response => {
          this.$store.dispatch('calculateOffer');
        });
    }
  },
  mounted(): void {}
});
