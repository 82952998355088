
import { Component, Prop, Vue } from 'vue-property-decorator';
import CalcManualDialog from '@/components/calculator/CalcManualDialog.vue';
@Component({
  components: {
    zaCalcManualDialog: CalcManualDialog
  }
})
export default class CalcManual extends Vue {
  hoverOrDraggingOver: boolean = false;
  dialog: boolean = false;
  activateDialog() {
    this.dialog = true;
  }
  deactivateDialog() {
    this.dialog = false;
  }
}
