
import { Vue } from 'vue-property-decorator';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import { formatOfferId } from '@/store';

export default Vue.extend({
  data: () => ({
    offers: [],
    confirmDialog: false,
    itemToDelete: null,
    dateOptions: {
      day: 'numeric',
      year: 'numeric',
      month: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
    filters: {
      filterNumber: '',
      filterType: '',
      filterName: '',
      filterCustomer: '',
      filterContact: '',
      filterUser: '',
      filterLastEditor: '',
      filterDate: '',
      filterStatus: ''
    },
    search: '',
    footerProps: {
      'items-per-page-options': [100, -1]
    }
  }),
  created() {
    this.retrieveOffers();
  },
  watch: {
    filters: {
      handler() {
        this.$cookies.set('offerFilters', this.filters, '1d');
      },
      deep: true
    }
  },
  mounted() {
    const offerFilters = this.$cookies.get('offerFilters');
    if (offerFilters) {
      this.filters = offerFilters;
    }
  },
  computed: {
    getUniqueStatuses() {
      return this.offers
        .map((offer: any) => offer.status)
        .filter((value: any, index: any, self: any) => self.indexOf(value) === index);
    },
    headers() {
      return [
        {
          text: 'Nummer',
          align: 'center',
          value: 'offer_id_formatted',
          width: '8%',
          filter: (value: any) => {
            if (!value) {
              value = '';
            }
            if (!this.filters.filterNumber) {
              return true;
            }
            return value.toString().includes(this.filters.filterNumber);
          }
        },
        {
          text: 'Typ',
          value: 'type',
          width: '6%',
          align: 'center',
          filter: (value: any) => {
            if (!value) {
              value = '';
            }
            if (!this.filters.filterType) {
              return true;
            }
            return value.toString().includes(this.filters.filterType);
          }
        },
        {
          text: 'Name',
          value: 'name',
          width: '15%',
          filter: (value: any) => {
            if (!value) {
              value = '';
            }
            if (!this.filters.filterName) {
              return true;
            }
            return value
              .toString()
              .toLowerCase()
              .includes(this.filters.filterName.toLowerCase());
          }
        },
        {
          text: 'Kunde',
          value: 'customer',
          width: '15%',
          filter: (value: any) => {
            if (!value) {
              value = '';
            }
            if (!this.filters.filterCustomer) {
              return true;
            }
            return value
              .toString()
              .toLowerCase()
              .includes(this.filters.filterCustomer.toLowerCase());
          }
        },
        {
          text: 'Kontakt',
          value: 'customer_contact',
          width: '15%',
          filter: (value: any) => {
            if (!value) {
              value = '';
            }
            if (!this.filters.filterContact) {
              return true;
            }
            return value
              .toString()
              .toLowerCase()
              .includes(this.filters.filterContact.toLowerCase());
          }
        },
        {
          text: 'Ersteller',
          value: 'user',
          width: '10%',
          filter: (value: any) => {
            if (!value) {
              value = '';
            }
            if (!this.filters.filterUser) {
              return true;
            }
            return value
              .toString()
              .toLowerCase()
              .includes(this.filters.filterUser.toLowerCase());
          }
        },
        {
          text: 'Letzter Bearbeiter',
          value: 'last_editor',
          width: '10%',
          filter: (value: any) => {
            if (!value) {
              value = '';
            }
            if (!this.filters.filterLastEditor) {
              return true;
            }
            return value
              .toString()
              .toLowerCase()
              .includes(this.filters.filterLastEditor.toLowerCase());
          }
        },
        {
          text: 'Status',
          align: 'center',
          value: 'status',
          width: '8%',
          filter: (value: any) => {
            if (!value) {
              value = '';
            }
            if (!this.filters.filterStatus) {
              return true;
            }
            return value
              .toString()
              .toLowerCase()
              .includes(this.filters.filterStatus.toLowerCase());
          }
        },
        {
          text: 'Datum',
          value: 'date',
          width: '8%',
          filter: (value: any) => {
            if (!value) {
              value = '';
            }
            if (!this.filters.filterDate) {
              return true;
            }
            value = new Date(value).toLocaleString('de-CH', this.dateOptions);
            return value
              .toString()
              .toLowerCase()
              .includes(this.filters.filterDate.toLowerCase());
          }
        },
        { text: 'Aktionen', value: 'action', sortable: false, width: '5%', align: 'center' }
      ];
    },
    excelVersion() {
      return this.$store.getters.excelVersion;
    },
    getOffers() {
      const options = {
        day: 'numeric',
        year: 'numeric',
        month: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      };
      return this.offers
        .filter((offer: any) => !offer.status.startsWith('Trainer'))
        .map((offer: any) => {
          offer.offer_id_formatted = formatOfferId(offer.offer_id);
          offer.speedDial = false;
          return offer;
        });
    },

    hasManufacturerRights() {
      return this.$store.getters.hasManufacturerRights;
    }
  },
  methods: {
    customSearch(value: any, search: any, item: any) {
      return Object.values(item).some((i: any) => {
        if (i) {
          return i
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        }
      });
    },
    getColorForStatus(status: string) {
      if (!status) {
        return '';
      }
      if (status === 'Entwurf' || status === 'E-Mail hochgeladen') {
        return '#FFEDB3';
      } else if (status === 'Wird verifiziert' || status === 'In Bearbeitung') {
        return '#FFFF71';
      } else if (status === 'Verifiziert' || status === 'Angebot versendet') {
        return '#DAF2D0';
      } else {
        return '';
      }
    },
    getIconForType(type: string) {
      return type === 'email' ? 'space_dashboard' : 'functions';
    },
    confirmDelete(offerId: any) {
      this.itemToDelete = offerId;
      this.confirmDialog = true; // Open the confirmation dialog
    },
    proceedToDelete() {
      if (this.itemToDelete !== null) {
        this.deleteItem(this.itemToDelete);
        this.itemToDelete = null; // Reset itemToDelete after deletion
      }
      this.confirmDialog = false; // Close the confirmation dialog
    },
    pdf(offerId: number) {
      axios
        .post(`${BACKEND_URL}/pdf`, { offer_id: offerId }, { responseType: 'blob' })
        .then(response => {
          let version = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${formatOfferId(offerId)}-V${version}.pdf`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {});
    },
    deleteItem(offerId: any) {
      axios
        .post(`${BACKEND_URL}/delete_offer`, { offer_id: offerId })
        .then(response => {
          this.retrieveOffers();
        })
        .catch(error => {});
    },
    loadEmailOffer(offerId: number) {
      this.$store.commit('resetOffer');
      axios
        .post(`${BACKEND_URL}/email_offer`, {
          offer_id: offerId
        })
        .then(response => {
          this.$store.commit('setOfferID', offerId);
          this.$store.commit('setOfferStatus', response.data.offer_status);
          let email = response.data.email;
          this.$store.commit('setEmail', {
            emailSender: email.sender,
            emailRecipients: email.recipients,
            emailBody: email.body,
            emailSubject: email.subject,
            emailAttachments: email.attachments,
            emailId: email.mail_id,
            excels: email.excels
          });
          this.$store.commit('setEmailContact', response.data.contact);
          this.$router.push('/offerdesk');
        });
    },
    loadOffer(offerId: number) {
      axios
        .post(`${BACKEND_URL}/offer`, { offer_id: offerId })
        .then(response => {
          this.$store.commit('resetOffer');
          if (response.status === 200) {
            if ('offer_id' in response.data) {
              this.$store.commit('setOffer', {
                offer_id: response.data.offer_id,
                offer_detail: response.data.offer_detail,
                name: response.data.offer_name
              });

              this.$store.commit('setOfferStatus', response.data.offer_status);

              for (const file of response.data.part_files) {
                this.$store.commit('addFile', file);
              }

              for (const part of response.data.parts) {
                this.$store.commit('addPart', part);
              }

              if (response.data.parts.length > 0) {
                this.$store.dispatch('calculateOffer');
              }
            }
          }
          this.$router.push('/calc');
        })
        .catch(error => {});
    },
    editItem(offer: any) {
      if (offer.type === 'email') {
        this.loadEmailOffer(offer.offer_id);
      } else {
        this.loadOffer(offer.offer_id);
      }
    },
    download(offerId: number) {
      axios.post(`${BACKEND_URL}/download_parts`, { offer_id: offerId }, { responseType: 'blob' }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${offerId}.zip`);
        document.body.appendChild(link);
        link.click();
      });
    },
    retrieveOffers() {
      axios
        .get(`${BACKEND_URL}/offers`)
        .then(response => {
          if (response.status === 200) {
            this.offers = response.data;
          }
        })
        .catch(error => {});
    },
    newOffer() {
      this.$store.commit('resetOffer');
      this.$router.push('/calc');
    }
  }
});
