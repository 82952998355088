export function formatNumber(value: any) {
  if (!value) {
    return value;
  }
  const splitted = value.toString().split('.');
  if (splitted.length == 1) {
    return splitted[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  } else {
    return splitted[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'") + '.' + splitted[1];
  }
}

export function round(value: number, decimals: number) {
  if (!value) {
    value = 0;
  }

  if (!decimals) {
    decimals = 0;
  }

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return value;
}
