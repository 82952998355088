
import { Component, Vue } from 'vue-property-decorator';
import CalcPropertiesItem from '@/components/calculator/CalcPropertiesItem.vue';

@Component({
  components: {
    zaCalcPropItem: CalcPropertiesItem
  }
})
export default class CalcProperties extends Vue {
  get parts() {
    return this.$store.getters.uploadedParts;
  }

  itemCreated() {}

  itemDeleted() {}

  updated() {}

  created() {
    this.$store.dispatch('loadMaterials');
  }
}
