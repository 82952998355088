
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MaterialInfo, ThicknessInfo } from '@/customer/part_details';
import { round } from '@/filters';

export interface MatThick {
  material_name: string;
  thickness_info: ThicknessInfo;
}

@Component
export default class MaterialSelect extends Vue {
  @Prop({ type: String, default: '' })
  material: any;

  @Prop({ type: Object, default: () => new ThicknessInfo() })
  thickness_info!: ThicknessInfo;

  @Prop({ type: Number, default: 0 })
  initial_thickness!: Number;

  local_material_info: any = {
    material: this.material,
    on_request: this.thickness_info.on_request,
    is_structural_steel: this.thickness_info.is_structural_steel
  };
  local_material: string = this.material;
  local_thickness: string = this.thickness_info.thickness;

  // getCorrectStringNum(number: any) {
  //   let val = Number(number);
  //   let res: any;
  //   if (String(number).split('.').length == 1) {
  //     res = val.toFixed(1);
  //   } else {
  //     res = String(number);
  //   }
  //   return res;
  // }

  @Watch('processedMaterials')
  onProcessedMaterialsChange() {
    this.setInitialThickness();
  }

  private setInitialThickness() {
    if (this.local_thickness === '' && this.initial_thickness !== 0 && this.initial_thickness !== null) {
      // check whether initial thickness is found in processed thicknesses
      let found = false;
      let resultThickness = '';
      for (let t of this.processedThicknesses) {
        if (round(Number(t.thickness), 2) === round(Number(this.initial_thickness), 2)) {
          resultThickness = t.thickness;
          found = true;
          break;
        }
      }
      if (found) {
        this.thicknessChanged(resultThickness);
      }
    }
  }

  thicknessChanged(thickness: string) {
    this.local_thickness = thickness;
    let needSet = true;
    for (let t of this.processedMaterials[this.local_thickness]) {
      if (this.local_material && t.material == this.local_material) {
        this.local_material = t.material;
        this.local_material_info = t;
        needSet = false;
        break;
      }
    }
    if (needSet) {
      this.local_material_info = new MaterialInfo();
      this.local_material = '';
    }
    this.emitInformationChange();
  }

  materialChanged(material_info: MaterialInfo) {
    this.local_material_info = material_info;
    this.local_material = material_info.material;
    this.emitInformationChange();
  }

  emitInformationChange() {
    let data: MatThick = {
      material_name: this.local_material,
      thickness_info: {
        thickness: this.local_thickness,
        on_request: this.local_material_info.on_request,
        is_structural_steel: this.local_material_info.is_structural_steel
      }
    };
    this.$emit('informationChanged', data);
  }

  get processedThicknesses() {
    const thicknesses: any[] = [{ header: 'Standard-Bleche' }];
    if (this.$store.getters.processedMaterials) {
      let itemsCopy = Object.keys(this.$store.getters.processedMaterials);
      itemsCopy.sort((a, b) => parseFloat(a) - parseFloat(b));
      for (let thickness of itemsCopy) {
        thicknesses.push({ thickness: thickness });
      }
    }
    if (this.$store.getters.processedMaterialsStructural) {
      thicknesses.push({ divider: true });
      thicknesses.push({ header: 'Struktur-Bleche' });
      let itemsCopy = Object.keys(this.$store.getters.processedMaterialsStructural);
      itemsCopy.sort((a, b) => {
        let a_a = a.split('/')[0];
        let a_b = a.split('/')[1];
        let b_a = b.split('/')[0];
        let b_b = b.split('/')[1];
        if (a_a === b_a) {
          return parseFloat(a_b) - parseFloat(b_b);
        }
        return parseFloat(a_a) - parseFloat(b_a);
      });
      for (let thickness of itemsCopy) {
        thicknesses.push({ thickness: thickness });
      }
    }

    return thicknesses;
  }

  get processedMaterials() {
    return {
      ...this.$store.getters.processedMaterials,
      ...this.$store.getters.processedMaterialsStructural
    };
  }

  mounted() {
    this.setInitialThickness();
  }
}
