
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CalcBend extends Vue {
  @Prop({ type: Array, default: [] })
  bendsProp: any;

  @Prop({ type: Number, default: 0 })
  availableBends!: number;

  bendLength = null;
  num = null;
  bendArc = null;
  valid = true;
  adding = false;

  initData() {
    this.bendLength = null;
    this.num = null;
    this.bendArc = null;
    this.valid = true;
  }

  reset() {
    this.initData();
    this.form.resetValidation();
    this.form.reset();
  }

  get form(): Vue & {
    validate: () => boolean;
    reset: () => boolean;
    resetValidation: () => boolean;
  } {
    return this.$refs.form as Vue & {
      validate: () => boolean;
      reset: () => boolean;
      resetValidation: () => boolean;
    };
  }

  addBend() {
    if (this.form.validate()) {
      for (let i = 0; i < this.num!; i++) {
        let payload = {
          bendLength: this.bendLength,
          bendArc: this.bendArc
        };
        this.$emit('add-bend', payload);
      }
      this.reset();
      this.adding = false;
    }
  }

  deleteBend(index: any) {
    this.$emit('delete-bend', index);
  }
}
