import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import jwtDecode from 'jwt-decode';
import Calculator from '@/views/Calculator.vue';
import Offers from '@/views/Offers.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'offers',
      component: Offers,
      meta: { requiresAuth: true, acc_level: 4 }
    },
    {
      path: '/calc',
      name: 'calc',
      component: Calculator,
      meta: { requiresAuth: true, acc_level: 4 }
    },
    {
      path: '/login',
      name: 'login',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/LogReg.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/excel',
      name: 'excel',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/LuckySheet.vue'),
      meta: { requiresAuth: true, acc_level: 2 }
    },
    {
      path: '/users',
      name: 'users',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Users.vue'),
      meta: { requiresAuth: true, acc_level: 2 }
    },
    {
      path: '/offerdesk',
      name: 'offerdesk',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/OfferDesk.vue'),
      meta: { requiresAuth: true, acc_level: 2 }
    },
    {
      path: '/params',
      name: 'params',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Params.vue'),
      meta: { requiresAuth: true, acc_level: 2 }
    },
    {
      path: '/params_hourly_cost',
      name: 'params_hourly_cost',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/ParamsHourlyCost.vue'),
      meta: { requiresAuth: true, acc_level: 2 }
    },
    {
      path: '/password_reset',
      name: 'password_reset',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/PasswordReset.vue'),
      meta: { requiresAuth: false }
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (from.path === '/offerdesk' || from.path === '/calc') {
    store.commit('resetOffer');
    store.commit('setEmail', null);
  }
  // check if "to" requires authentication
  let record = to.matched.find(record => record.meta.requiresAuth);
  if (record) {
    if (store.getters.isAuthenticated) {
      store.dispatch('updateRefreshDefaults');
      if (store.getters.userRole <= record.meta.acc_level) {
        next();
      } else {
      }
    } else {
      // try to load jwt cookie
      const jwt_header_payload = Vue.$cookies.get('jwt_header_payload');
      if (jwt_header_payload) {
        // set user and continue
        const user = jwtDecode(jwt_header_payload);
        store.commit('setUser', user);
        store.dispatch('updateRefreshDefaults');
        next();
      } else {
        // requires auth, is not authenticated and has no cookie
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        });
      }
    }
  } else {
    // requires no auth --> make sure is not auth, may change in future
    if (!store.getters.isAuthenticated) {
      next();
    }
  }
});

export default router;
