
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class CalcManualDialogGeometry extends Vue {
  type = 'Rechteckig';
  diameter = null;
  num = null;
  length = null;
  width = null;
  valid = true;
  initData() {
    this.diameter = null;
    this.num = null;
    this.length = null;
    this.width = null;
    this.valid = true;
  }
  rules = [(v: any) => !!v];
  reset() {
    this.initData();
    this.form.resetValidation();
    this.form.reset();
  }
  deactivateDialog() {
    this.reset();
    this.$emit('close-dialog-geometry');
  }
  get form(): Vue & {
    validate: () => boolean;
    reset: () => boolean;
    resetValidation: () => boolean;
  } {
    return this.$refs.form as Vue & {
      validate: () => boolean;
      reset: () => boolean;
      resetValidation: () => boolean;
    };
  }

  addGeometry() {
    if (this.form.validate()) {
      let payload: any = { type: this.type, num: this.num };
      if (this.type === 'Rund') {
        payload['diameter'] = this.diameter;
      } else {
        payload['width'] = this.width;
        payload['length'] = this.length;
      }
      this.$emit('add-inner-geometry', payload);
      this.reset();
      this.$emit('close-dialog-geometry');
    }
  }
}
