<template>
  <div>
    <v-label>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">info</v-icon>
        </template>
        <span>Keine Preisgarantie bei Verwendung von Biegungen</span>
      </v-tooltip>
      Gewinde
    </v-label>
    <v-btn @click="adding = true" icon v-if="!adding">
      <v-icon>add</v-icon>
    </v-btn>
    <v-list style="max-height: 100px" class="overflow-y-auto">
      <v-list-item v-for="(thread, index) in threadsProp" :key="`g${index}`">
        <v-list-item-content>
          <v-list-item-title v-if="thread.on_request" style="font-style: italic"
            >* {{ thread.num }}x {{ thread.thread_size }}
          </v-list-item-title>
          <v-list-item-title v-else>{{ thread.num }}x {{ thread.thread_size }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon @click="deleteThread(index)">delete</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-container v-if="adding" class="mt-0 pt-0">
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-row align="center">
          <v-col cols="3">
            <v-text-field
              label="Anzahl"
              type="number"
              value="1"
              key="num"
              v-model.number="num"
              :rules="[v => !!v || 'Anzahl definieren']"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-select
              label="Gewinde"
              key="kd"
              :items="availableThreads"
              item-text="thread_size"
              v-model="threadType"
              :rules="[v => !!v || 'Gewindetyp definieren']"
              return-object
              required
            >
              <template v-slot:selection="data">
                <div v-if="data.item.on_request" style="font-style:italic">* {{ data.item.thread_size }}</div>
                <div v-else>{{ data.item.thread_size }}</div>
              </template>
              <template v-slot:item="data">
                <v-list-item-content v-if="data.item.on_request">
                  <v-list-item-title style="font-style:italic"> * {{ data.item.thread_size }} </v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title>{{ data.item.thread_size }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
          </v-col>
          <v-col cols="1">
            <v-btn @click="addThread" icon>
              <v-icon>check</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1">
            <v-btn @click="adding = false" icon>
              <v-icon>clear</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'CalcThread',
  props: ['threadsProp', 'availableThreads'],
  data: function() {
    return {
      threadType: null,
      num: null,
      valid: true,
      adding: false
    };
  },
  methods: {
    initData() {
      this.threadType = null;
      this.num = null;
      this.valid = true;
    },
    reset() {
      this.initData();
      this.form.resetValidation();
      this.form.reset();
    },
    addThread() {
      if (this.form.validate()) {
        let payload = {
          num: this.num,
          ...this.threadType
        };
        this.reset();
        this.adding = false;
        this.$emit('add-thread', payload);
      }
    },
    deleteThread(index) {
      this.$emit('delete-thread', index);
    }
  },
  computed: {
    form() {
      return this.$refs.form;
    }
  }
};
</script>

<style scoped></style>
