export class DefaultValuesCustomer implements DefaultValues {
  disclaimer: string;
  maxLongLength: number;
  maxShortLength: number;
  offerValidityDays: number;

  constructor(disclaimer: string, maxLongLength: number, maxShortLength: number, offerValidityDays: number) {
    this.disclaimer = disclaimer;
    this.maxLongLength = maxLongLength;
    this.maxShortLength = maxShortLength;
    this.offerValidityDays = offerValidityDays;
  }
}
